@keyframes rainbow {
    0%   {background-color: red;}
    20%  {background-color: yellow;}
    40%  {background-color: rgb(7, 199, 7);}
    60%  {background-color: blue;}
    80% {background-color: rgb(226, 1, 185);}
    100% {background-color: red;}
  }
  
  /* The element to apply the animation to */
  .rainbow-anim {
    background-color: red;
    animation-name: rainbow;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }